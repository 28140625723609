import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import swal from 'sweetalert2';
import {ResponseModel} from './../models/response.model';
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ToastService {
  constructor(private translate: TranslateService) {}

  delete(func: Observable<ResponseModel<any>>, callback, text = 'toast_notices.successfully_deleted') {
    let self = this;
    swal.fire({
      title: this.translate.instant('toast_notices.are_you_sure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2F8BE6',
      cancelButtonColor: '#F55252',
      confirmButtonText: this.translate.instant('common.yes'),
      cancelButtonText: this.translate.instant('common.no'),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    }).then(function (result) {
      if (result.value) {
        func.subscribe(d => {
          if ((d as any).ok == false) {
            self.error((d as any).status);
          }
          else {
            callback();
            if (d.hasError) {
              self.error(
                d.errors.map((err) => err.description).join(', ')
              );
            }
            else {
             self.success('', text);
            }
          }

        });
      }
    });
  }

  success(text = '', title = 'toast_notices.action_completed_successfully') {

    swal.fire({
      title: this.translate.instant(title),
      text: text ? this.translate.instant(text) : text,
      icon: "success",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
  }

  info(title, text) {
    swal.fire({
      title: title,
      text: text,
      icon: "info",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
  }

  warning(title, text) {
    swal.fire({
      title: this.translate.instant(title),
      text: this.translate.instant(text),
      icon: "warning",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
  }

  error(text) {
    swal.fire({
      title: this.translate.instant('toast_notices.error'),
      text: this.translate.instant(text),
      icon: "error",
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
  }

}





